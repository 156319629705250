export function calculateImageSize(columns: number, parentWidth?: number) {
  let sizes = "100vw";

  if (!parentWidth) {
    switch (columns) {
      case 1:
        sizes = "100vw";
        break;
      case 2:
        sizes = "(max-width: 640px) 100vw, 50vw";
        break;
      case 3:
        sizes =
          "(max-width: 640px) 100vw, (min-width: 640px) and (max-width: 768px) 50vw, 33vw";
        break;
      case 4:
        sizes =
          "(max-width: 640px) 50vw, (min-width: 640px) and (max-width: 768px) 50vw, (min-width: 768px) and (max-width: 1280px) 33vw, 25vw";
        break;
      case 5:
        sizes =
          "(max-width: 768px) 50vw, (min-width: 768px) and (max-width: 920px) 33vw, 20vw";
        break;
      case 6:
        sizes =
          "(max-width: 768px) 50vw, (min-width: 768px) and (max-width: 1280px) 33vw, 16vw";
        break;
    }
  }

  if (parentWidth) {
    switch (columns) {
      case 1:
        sizes = `(max-width: 768px) 100vw, ${parentWidth}vw`;
        break;
      case 2:
        sizes = `(max-width: 768px) 50vw, ${parentWidth / 2}vw`;
        break;
      case 3:
        sizes = `(max-width: 640px) 100vw, (min-width: 640px) and (max-width: 768px) 50vw, ${
          parentWidth / 3
        }vw`;
        break;
      case 4:
        sizes = `(max-width: 768px) 50vw, (min-width: 768px) and (max-width: 1280px) ${
          parentWidth / 3
        }vw, ${parentWidth / 4}vw`;
        break;
      case 5:
        sizes = `(max-width: 768px) 50vw, (min-width: 768px) and (max-width: 920px) ${
          parentWidth / 3
        }vw, ${parentWidth / 5}vw`;
        break;
      case 6:
        sizes = `(max-width: 768px) 50vw, (min-width: 768px) and (max-width: 1280px) ${
          parentWidth / 3
        }vw, ${parentWidth / 6}vw`;
        break;
    }
  }

  return sizes;
}
